import React, { useEffect } from 'react';

function App() {

  useEffect(() => {
    const currentPath = window.location.pathname; // Current path
    const queryParams = window.location.search;  // Current params
    const newDomain = "https://new.packagemxca.com";
    window.location.href = `${newDomain}${currentPath}${queryParams}`;
  }, []);

  return ( <h1>Redirigiendo...</h1> );
}

export default App;
